import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import AppHeader from "@csis.com/tip/src/components/AppHeader/AppHeader";
import { SidebarWrapper as Sidebar } from "@csis.com/tip/src/components/wrappers/SidebarWrapper";
import sidebarConfig from "@csis.com/tip/src/config/sidebarConfig";
import Loading from "@csis.com/tip/src/pages/Loading/Loading";
import * as loginSelectors from "@csis.com/tip/src/pages/Login/selectors";
import {
  postLogout,
  setRedirectUrl,
} from "@csis.com/tip/src/pages/Login/slice";
import urls from "@csis.com/tip/src/routes/urls";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import AppErrorBoundary from "./AppErrorBoundary";
import {
  getHasUserRequestedOrgSwitch,
  getSelectedOrgId,
} from "./pages/Profile/Security/selectors";
import routes from "./routes/routes";
import {
  getUserPreferences,
  getUserPreferencesForGeneral,
} from "./userPreferences/selectors";
import { fetchUserPreferences } from "./userPreferences/slice";
import { setHighContrast, setTheme } from "./utils/setTheme";
import { useUpdateAndReplaceQueryParams } from "./utils/updateAndReplaceQueryParams";

function MainApp() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslations();
  const themePreference = useSelector(
    getUserPreferencesForGeneral
  )?.themePreference;
  const prefersHighContrast = useSelector(
    getUserPreferencesForGeneral
  )?.prefersHighContrast;
  const isSystemThemeDark = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const selectedOrgId = useSelector(getSelectedOrgId);
  const updateAndReplaceQueryParams = useUpdateAndReplaceQueryParams();

  useEffect(() => {
    if (location.pathname === urls.logout) {
      dispatch(postLogout());
      return;
    }

    if (selectedOrgId && !location.search.includes("organizationId")) {
      updateAndReplaceQueryParams("organizationId", selectedOrgId);
    }
  }, [
    dispatch,
    location.pathname,
    location.search,
    selectedOrgId,
    updateAndReplaceQueryParams,
  ]);

  const userPreferences = useSelector(getUserPreferences);

  useEffect(() => {
    if (isNullOrUndefined(userPreferences)) {
      dispatch(fetchUserPreferences());
    }

    // Theme handling logic
    const effectiveThemePreference =
      themePreference === "system"
        ? isSystemThemeDark
          ? "dark"
          : "light"
        : themePreference;
    setTheme(effectiveThemePreference || "light");

    // High contrast setting
    setHighContrast(prefersHighContrast || false);
  }, [
    themePreference,
    isSystemThemeDark,
    userPreferences,
    dispatch,
    prefersHighContrast,
  ]);

  useEffect(() => {
    setHighContrast(prefersHighContrast || false);
  }, [prefersHighContrast]);

  const redirectUrl = useSelector(loginSelectors.getRedirectUrl);
  const currentUrl = window.location.pathname + window.location.search;

  if (redirectUrl && redirectUrl !== currentUrl) {
    // if there is a redirectUrl - after a successful login, redirect the user there
    // and "null-ify" it
    dispatch(setRedirectUrl(null));
    history.push(redirectUrl);
  }

  // use effect hook that listens for changes in the selectedOrgId and redirects to the main page
  // we need also a flag (shouldRedirectToMainPage) to prevent the redirect when the user is not
  // switching orgs - so in the first render we don't redirect
  // we also dont want to redirect when a user visits the page via a deep link that contains the org id
  const shouldRedirectToMainPage = useSelector(getHasUserRequestedOrgSwitch);
  useEffect(() => {
    if (selectedOrgId && shouldRedirectToMainPage) {
      history.push({
        pathname: urls.products,
        search: `organizationId=${selectedOrgId}`,
      });
    }
  }, [shouldRedirectToMainPage, history, selectedOrgId]);

  return (
    <div className="main-app">
      <AppErrorBoundary>
        <Sidebar route={location.pathname} menuItems={sidebarConfig} />
      </AppErrorBoundary>
      <div className="main-app__container">
        <Switch>
          {routes.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              render={() => (
                <AppHeader
                  pageTitle={t(route.title)}
                  pageSubTitle={t(route.subtitle)}
                />
              )}
            />
          ))}
        </Switch>
        <AppErrorBoundary>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={urls.products} />}
              />
              {routes.map((route) => (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </Suspense>
        </AppErrorBoundary>
      </div>
    </div>
  );
}

export default MainApp;
