import { History } from "history";
import * as qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { isString } from "../models/helpers";

export const stringifyParams = (queryParams: { [key: string]: any }) =>
  qs.stringify(queryParams, { arrayFormat: "index" });

export const getNewLocationUrl = (
  url: string,
  queryParams: { [key: string]: any }
) => url + "?" + stringifyParams(queryParams);

function updateLocationWithHistoryProvided(
  history: History<unknown>,
  organizationId?: string
) {
  return function updateLocationWithParams(
    url: string,
    queryParams: { [key: string]: any },
    shouldReplace = false
  ) {
    const newLocationURL = getNewLocationUrl(url, {
      organizationId,
      ...queryParams,
    });

    // in some cases we want to replace the current location to avoid creating a new entry in the history stack
    if (shouldReplace) {
      history.replace(newLocationURL);
    } else {
      history.push(newLocationURL);
    }
  };
}

export function useUpdateLocationWithParams() {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);
  const organizationId =
    params.organizationId && isString(params.organizationId)
      ? params.organizationId
      : undefined;

  // return a partial function that is called already with history
  // so it needs only url and query params
  return updateLocationWithHistoryProvided(history, organizationId);
}
