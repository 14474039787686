import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileKeys } from "@csis.com/tip/src/pages/Profile/Security/constants";
import { TipProduct } from "@csis.com/tip/src/pages/Profile/Security/models/TipProduct";
import {
  getIsProfilePending,
  getProfile,
  getSelectedOrgId,
  getUserSubscribedProducts,
} from "@csis.com/tip/src/pages/Profile/Security/selectors";
import urls, { AllowedTipUrl } from "@csis.com/tip/src/routes/urls";
import { setSelectedOrgId } from "../../pages/Profile/Security/slice";
import { getSelectedOnboardingStep } from "../OnboardingHelp/selectors";
import Sidebar from "../shared/Sidebar/Sidebar";
import { MenuItems } from "../shared/Sidebar/types";

export const SidebarWrapper: React.FC<{
  menuItems: MenuItems<AllowedTipUrl, TipProduct>;
  route?: string;
}> = React.memo(({ menuItems, route = "" }) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector(getProfile)?.[profileKeys.IS_SUPPOSEDLY_ADMIN];
  const userSubscribedProducts = useSelector(getUserSubscribedProducts);
  const isFetchSubscribedProductsPending = useSelector(getIsProfilePending);

  const organizations = useSelector(getProfile)?.[profileKeys.LEGACY_ORGS];

  const defaultSelectedOrg = organizations?.[0]?.external_id;
  const selectedOrgId = useSelector(getSelectedOrgId) || defaultSelectedOrg;

  const selectedOnboardingStep = useSelector(getSelectedOnboardingStep);

  const handleSelectedOrgChange = useCallback(
    (organizationId: string) => {
      dispatch(setSelectedOrgId({ organizationId }));
    },
    [dispatch]
  );

  return (
    <Sidebar
      menuItems={menuItems}
      route={route}
      isAdmin={isAdmin}
      isMenusPending={isFetchSubscribedProductsPending}
      enabledMenus={userSubscribedProducts}
      selectedOnboardingStep={selectedOnboardingStep}
      organizations={organizations}
      selectedOrgId={selectedOrgId}
      onSelectedOrgChange={handleSelectedOrgChange}
      homeUrl={urls.products}
    />
  );
});
