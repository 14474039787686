// https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps#step-1-create-a-code-verifier
import { AllowedAuthEndpoint } from "./apiEndpoints";

function dec2hex(dec: number) {
  const hex = "0" + dec.toString(16);
  return hex.substring(hex.length - 2);
}

// Use this to generate a "verifier"
export function generateRandomString() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

function sha256(plain: string) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a: ArrayBuffer) {
  let str = "";
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i] as number);
  }
  return window
    .btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// use this to generate code challenge
export async function getChallengeFromVerifier(v: string) {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
}

const PERMISSION_SCOPES = [
  process.env.REACT_APP_CSIS_REDIRECT_URI + "article:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "auditlog:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "chronos:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "data-platform:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "phish:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "phish:write",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "report:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "report:write",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "retainer:read",
  process.env.REACT_APP_CSIS_REDIRECT_URI + "retainer:write",
];

export function generateAuthRedirectParams(challenge: string, state: string) {
  return {
    client_id: process.env.REACT_APP_CSIS_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_CSIS_REDIRECT_URI,
    response_type: "code",
    scope: "openid email " + PERMISSION_SCOPES.join(" "),
    code_challenge: challenge,
    code_challenge_method: "S256",
    state: state,
  };
}

export function generateAuthLogoutParams(idToken: string) {
  return {
    id_token_hint: idToken,
    client_id: process.env.REACT_APP_CSIS_CLIENT_ID,
    post_logout_redirect_uri: process.env.REACT_APP_CSIS_REDIRECT_URI,
  };
}

export function getEndpointWithParams(
  endpoint: AllowedAuthEndpoint,
  params: string
) {
  return process.env.REACT_APP_CSIS_AUTH_ENDPOINT + endpoint + "?" + params;
}
